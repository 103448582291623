body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }
  .fm-example {
    padding: 2rem;
    min-height: 150vh;
  }
  
  .fm-example > * {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
  }
  
.meeting-grid {
    list-style-type: none;
    /* display: grid; */
    /* grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    grid-auto-rows: 8rem; */
    padding: 0;
    margin: 0;
  }
  
  .fm-list {
    grid-gap: 1rem;
    margin: 2rem 0;
  }
  
  .fm-list > ul,
  .fm-grid > ul {
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 1rem;
  }
  
  .fm-list .fm-item {
    margin-bottom: 1rem;
  }
  
  .fm-list .fm-item:last-of-type {
    margin-bottom: 0;
  }
  
  .fm-item {
    overflow: hidden;
    display: block;
    border-radius: 40px;
    background-color: white;
  }
  
  .fm-item h3 {
    font-size: 1.1rem;
  }
  
  .fm-item > div {
    padding: 0 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .fm-item h3 {
    margin-bottom: 0;
  }
  
  .fm-item p {
    font-style: italic;
    margin-top: 0;
  }
  

  .fm-show-all {
    padding: 0.5rem 1rem;
    background-color: #ff4057;
    color: white;
    font-weight: bold;
    border: 0;
    border-radius: 3px;
  }
  
  .fm-flex-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .fm-flex-container > label {
    min-width: 4.5rem;
  }
  
  legend {
    font-weight: bold;
  }
  
  .fm-example fieldset {
    border: 0;
    padding: 1rem;
  }
  
  .fm-example label {
    margin-right: 1.5rem;
    cursor: pointer;
  }
  
  .fm-example input {
    position: relative;
    margin-right: 0.5rem;
  }
  #zmmtg-root {
    display: none;
  }




 

  
 


  .contents {
    display: grid;
    place-items: center;
    transition: filter 0.2s ease-out, background-color 0.2s ease-out;
    font-size: 16px;
  }
  
  .dragging {
    filter: blur(5px);
    
  }

  .react-grid-layout {
    position: relative;
    transition: height 200ms ease;
  }
  .react-grid-item {
    
    overflow-y: scroll;
  }
  .react-grid-item.cssTransforms {
    transition-property: transform;
  }
  .react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
  }
  
  .react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
  }
  
  .react-grid-item.react-grid-placeholder {
    background: gray;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  .react-grid-item.react-grid-placeholder.placeholder-resizing {
    transition: none;
  }
  
  .react-grid-item > .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    
  }
  
  .react-grid-item > .react-resizable-handle::after {
    content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 10px;
    height: 10px;
    border-right: 2px solid rgba(0, 0, 0, 0.4);
    border-bottom: 2px solid rgba(0, 0, 0, 0.4);
  }
  
  .react-resizable-hide > .react-resizable-handle {
    display: none;
  }
  
  .react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
    transform: rotate(90deg);
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
    transform: rotate(180deg);
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
    transform: rotate(270deg);
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-w,
  .react-grid-item > .react-resizable-handle.react-resizable-handle-e {
    top: 50%;
    margin-top: -10px;
    cursor: ew-resize;
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-w {
    left: 0;
    transform: rotate(135deg);
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-e {
    right: 0;
    transform: rotate(315deg);
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-n,
  .react-grid-item > .react-resizable-handle.react-resizable-handle-s {
    left: 50%;
    margin-left: -10px;
    cursor: ns-resize;
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-n {
    top: 0;
    transform: rotate(225deg);
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-s {
    bottom: 0;
    transform: rotate(45deg);
  }

  .ProseMirror {
    height: 100%;
    padding: 10px;
    outline: none;
  }