@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .formTextField-input {
    @apply block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm
  }
  .formTextField-error {
    @apply block w-full appearance-none rounded-md border border-red-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm
  }
  .sample-drag-bar {
    @apply shrink-0 p-1 bg-white cursor-col-resize transition-all hover:bg-gray-50
  }
  .sample-drag-bar--horizontal {
    @apply shrink-0 p-1 w-full cursor-row-resize 
  }
  .sample-drag-bar--dragging {
    @apply bg-blue-600
  }


}

